import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://cc10302d151eb69da3b69a3ac9ce680a@o4508292470276096.ingest.de.sentry.io/4508292476895312",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});